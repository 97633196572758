import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import ProjectTile from '../components/ProjectTile'
import ProjectFilters from '../components/ProjectFilters'
import GetInTouch from '../components/GetInTouch'
import SEO from '../components/seo'
import METADATA from '../metadata/metadata'
import './projects.scss'

const ProjectsPage = ({
  data: {
    allSanityProject: { nodes: projects },
    allSanityCategory: { nodes: categories },
  },
}) => {
  const [activeOrder, setActiveOrder] = useState('new')
  const [activeCategories, setActiveCategories] = useState([])
  const projectsRef = useRef(null)

  const projectsOrdered =
    activeOrder === 'new' ? projects : [...projects].reverse()

  const projectsFiltered =
    activeCategories.length === 0
      ? projectsOrdered
      : projectsOrdered.filter(project => {
          return project.categories.some(category =>
            activeCategories.includes(category.name)
          )
        })

  return (
    <>
      <SEO
        title={METADATA.projects.title}
        description={METADATA.projects.description}
      />
      <section className="ProjectPage">
        <ProjectFilters
          setActiveOrder={setActiveOrder}
          setActiveCategories={setActiveCategories}
          categories={categories}
        />
        <div className="ProjectPage_projects" ref={projectsRef}>
          {projectsFiltered.map(project => (
            <ProjectTile {...project} key={project.id} />
          ))}
        </div>
      </section>
      <GetInTouch />
    </>
  )
}

export const query = graphql`
  query ProjectsQuery {
    allSanityProject(sort: { fields: [_createdAt], order: DESC }) {
      nodes {
        id
        street
        slug {
          current
        }
        categories {
          name
        }
        pictures {
          cover
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    allSanityCategory {
      nodes {
        name
      }
    }
  }
`

export default ProjectsPage
