import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import getCoverPicture from '../utilities/getCoverPicture'
import './ProjectTile.scss'

const ProjectTile = ({ street, categories, slug, pictures }) => {
  const cover = getCoverPicture(pictures)
  return (
    <motion.article className="projectTile" whileHover={{ scale: 1.06 }}>
      <Link to={`/project/${slug.current}?showBack=true`}>
        <Img fluid={cover.asset.fluid} className="projectTile_image" />
        <section className="projectTile_info">
          <h5 className="projectTile_street">{street}</h5>
          <ul className="projectTile_categories">
            {categories.map(category => (
              <span key={category.name}>{category.name}</span>
            ))}
          </ul>
        </section>
      </Link>
    </motion.article>
  )
}

ProjectTile.propTypes = {
  street: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  pictures: PropTypes.array.isRequired,
  slug: PropTypes.object.isRequired,
}

export default ProjectTile
