import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import './ProjectFilters.scss'

const ORDER = [
  { value: 'new', label: 'Newest first' },
  { value: 'old', label: 'Oldest first' },
]

const ProjectFilters = ({
  setActiveCategories,
  setActiveOrder,
  categories,
}) => {
  const updateActiveOrder = activeOrder => {
    setActiveOrder(activeOrder.value)
  }

  const CATEGORIES = categories.map((cat, index) => ({
    value: cat.name,
    label: cat.name,
    index,
  }))

  const [defaultFromLocalStorage, setDefaultFromLocalStorage] = useState([])

  // get activeCategories from localstorage on mount
  useEffect(() => {
    const cats = JSON.parse(
      window.localStorage.getItem('aghicks_activeCategories')
    )

    if (!cats) {
      return
    }
    const referencesToExistingCats = cats.map(cat => CATEGORIES[cat.index])
    setDefaultFromLocalStorage(referencesToExistingCats)
    const categoryValues = cats.map(category => category.value)
    setActiveCategories(categoryValues)
  }, [])

  const updateActiveCategories = activeCategories => {
    if (activeCategories) {
      window.localStorage.setItem(
        'aghicks_activeCategories',
        JSON.stringify(activeCategories)
      )
      const categoryValues = activeCategories.map(category => category.value)
      setActiveCategories(categoryValues)
    } else {
      setActiveCategories([])
    }
  }

  return (
    <nav className="ProjectFilters">
      <div className="ProjectFilters_filter">
        <label htmlFor="order">Order</label>
        <Select
          options={ORDER}
          defaultValue={ORDER[0]}
          id="order"
          className="ProjectFilters_select"
          onChange={updateActiveOrder}
        />
      </div>
      <div className="ProjectFilters_filter">
        <label htmlFor="category">Type of project</label>
        <Select
          options={CATEGORIES}
          className="ProjectFilters_select"
          isMulti
          id="category"
          key={defaultFromLocalStorage}
          onChange={updateActiveCategories}
          defaultValue={defaultFromLocalStorage}
        />
      </div>
    </nav>
  )
}

ProjectFilters.propTypes = {
  setActiveCategories: PropTypes.func.isRequired,
  setActiveOrder: PropTypes.func.isRequired,
  categories: PropTypes.array,
}

export default ProjectFilters
